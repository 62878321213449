import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { Observable, catchError, map, of, switchMap, throwError } from 'rxjs';
import { IRestResponse } from '../core/interface/response.interface';
import { Environment } from '../../environments/environment';
import { IChangePwdPayload, IForgotPwdPayload, ISetPwdPayload } from '../interfaces/user.interface';
import { Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private restService: RestService, private router: Router) { }

  public changePassword(payload: IChangePwdPayload): Observable<IRestResponse<any>> {
    return this.restService
      .put(
        Environment.UrlPath + `/user/change/password`,
        payload,
        false
      )
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }

  public setPassword(payload: ISetPwdPayload): Observable<IRestResponse<any>> {
    return this.restService
      .put(
        Environment.UrlPath + `/user/setPassword`,
        payload,
        false
      )
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }
  public forgotPassword(payload: IForgotPwdPayload): Observable<IRestResponse<any>> {
    return this.restService
      .put(
        Environment.UrlPath + `/user/forgotPassword`,
        payload,
        false
      )
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }

  public isWabaVerified(): Observable<boolean | UrlTree> {
    return this.restService.get(Environment.UrlPath + `/company/user/WABA`, null, false)
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error)),
        switchMap((res) => res.response ? of(true) : this.router.navigate(['waba-verification'])
        )
      )
  }

}
